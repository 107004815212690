import React from 'react'
import withMobileDialog from "@material-ui/core/withMobileDialog";
import {Divider} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const logo = '/static/images/Asistensi_logotipo.svg';
const logo_white = '/static/images/logo.svg';

const serverUrlImages = process.env.REACT_APP_BASE_PATH_FILES;
const umbrella = `${serverUrlImages}acunsa/umbrella.jpg`;
const danger = `${serverUrlImages}acunsa/danger.jpg`;
const alert = `${serverUrlImages}acunsa/alert.jpg`;
const world = `${serverUrlImages}acunsa/world.jpg`;
const hand = `${serverUrlImages}acunsa/hand.jpg`;
const euro = `${serverUrlImages}acunsa/euro.jpg`;
const clock = `${serverUrlImages}acunsa/clock.jpg`;
const stop = `${serverUrlImages}acunsa/stop.jpg`;

const TermsDIP = () => {
    return (
        <div className="max-w-5xl mx-auto w-ful px-3">
            <div className="px-7 py-4 bg-purple-500 rounded-xl " >
                <div className="flex flex-col">
                    <div className="w-full flex justify-center md:justify-end">
                        <img src={logo} alt="asistensi_logotipo" className="sm:hidden md:px-6 w-6/12 md:max-w-90 h-auto" />
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="w-full flex flex-col mr-6 sm:w-2/3  mt-10">
                            <h1 className="font-bold uppercase  text-base md:text-3xl text-pink-500">SEGURO DE ASISTENCIA SANITARIA </h1>
                            <h3 className="text-base md:text-lg text-white">Documento de Información sobre el producto de seguro</h3>
                        </div>
                        <div className="flex-1 hidden sm:block">
                            <img src={logo} alt="asistensi_logotipo" className="md:px-6 max-w-full md:max-w-90 h-auto" />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row w-full md:items-center md:justify-between sm:justify-left  mt-6 text-white">
                    <div className="mr-0 md:mr-6 w-auto md:h-14">
                        <span className="text-md md:text-lg ">
                            <b>Empresa: </b>
                            <span className='pl-2 md:pl-0'>Asistencia Clínica Universitaria de Navarra, S.A.</span>
                        </span>
                    </div>
                    <div className="ml-0 flex-1 text-sm">
                        <div className="text-md md:text-lg text-left sm:text-right flex  flex-row ">
                            <b className='w-20 md:w-20 text-left'>Producto: </b>
                            <div className='flex-1 flex flex-row space-x-4 md:space-x-0 md:pl-5 md:flex-col text-left'>
                                <span className="font-normal uppercase  "> PLAN ASISTENSI  </span>
                                <span className="font-normal uppercase  "> PLAN ASISTENSI PLUS </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Divider/>
            <div className="mt-6">
                <p className="text-center underline">
                La información precontractual y contractual completa relativa al producto se facilita en otros documentos
                </p>
                <p className="text-center mt-5">
                Este documento contiene un resumen del producto.
                </p>
            </div>
            <div className="mt-6 mb-10">
                <div>
                    <span className="font-bold text-xl">¿En qué consiste este tipo de seguro?</span>
                    <p className="text-justify">
                    Es un seguro de asistencia sanitaria <b>para consultas online y telefónicas, pruebas de laboratorio, entrega de medicamentos, atención domiciliaria primaria, traslados y emergencias de familiares y allegados del tomador</b> que residan en la República Bolivariana de Venezuela y sean beneficiarios del seguro.
                    </p>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 auto-cols-fr">
                <div className="border-purple-200 border rounded-xl bg-purple-50 px-6 py-3">
                    <div className="flex flex-row items-center">
                        <img src={umbrella} alt="umbrella" className="w-12 h-12"/>
                        <h2 className="ml-6 font-bold text-2xl text-purple-500 ">¿Qué se asegura?</h2>
                    </div>
                    <div className="mt-6 ">
                        <ul className="space-y-2">
                            <li>
                                <span className="mr-3"><CheckIcon className="text-green-400"/></span>
                                <span className="text-purple-500 ">Orientación médica telefónica o virtual</span>
                            </li>
                            <li>
                                <span className="mr-3"><CheckIcon className="text-green-400"/></span>
                                <span className="text-purple-500 ">Atenciones primarias básicas en domicilio</span>
                            </li>
                            <li>
                                <span className="mr-3"><CheckIcon className="text-green-400"/></span>
                                <span className="text-purple-500 ">Entrega de medicamentos a domicilio y/o en centros de salud</span>
                            </li>
                            <li>
                                <span className="mr-3"><CheckIcon className="text-green-400"/></span>
                                <span className="text-purple-500 ">Pruebas de laboratorio clínico:</span>
                                <ul className="ml-14 list-disc space-y-2">
                                    <li className="text-green-400">
                                        <span className="text-purple-500 ">Perfil infección urinaria</span>
                                    </li>
                                    <li className="text-green-400">
                                        <span className="text-purple-500 ">Perfil infección básico</span>
                                    </li>
                                    <li className="text-green-400">
                                        <span className="text-purple-500 ">Perfil infección enteral</span>
                                    </li>
                                    <li className="text-green-400">
                                        <span className="text-purple-500 ">Perfil infección completo</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span className="mr-3"><CheckIcon className="text-green-400"/></span>
                                <span className="text-purple-500 ">Traslados:</span>
                                <ul className="ml-14 list-disc space-y-2">
                                    <li className="text-green-400">
                                        <span className="text-purple-500 ">Traslado de retorno o alta médica</span>
                                    </li>
                                    <li className="text-green-400">
                                        <span className="text-purple-500 ">Traslado de terapia intensiva</span>
                                    </li>
                                    <li className="text-green-400">
                                        <span className="text-purple-500 ">Traslado a otro centro de salud</span>
                                    </li>
                                    <li className="text-green-400">
                                        <span className="text-purple-500 ">Traslado en ambulancias</span>
                                    </li>
                                    <li className="text-green-400">
                                        <span className="text-purple-500 ">Traslado programado</span>
                                    </li>
                                </ul>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3"><CheckIcon className="text-green-400"/></span>
                                <span className="text-justify text-purple-500 ">Asistencia médica en centros de salud hasta un máximo por año de $5,000 (PLAN ASISTENSI) o $10,000 (PLAN ASISTENSI PLUS)</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="border-purple-200 border rounded-xl bg-purple-50  px-6 py-3">
                    <div className="flex flex-row items-center">
                        <img src={danger} alt="danger" className="w-12 h-12"/>
                        <h2 className="ml-6 font-bold text-2xl text-purple-500 ">¿Qué no está asegurado?</h2>
                    </div>
                    <div className="mt-6">
                        <ul className="space-y-2">
                            <li className="flex flex-row">
                                <span className="mr-3"><ClearIcon className="text-red-500"/></span>
                                <span  className="text-justify text-purple-500 ">Tratamientos de enfermedades prexistentes
                                o enfermedades congénitas</span>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3"><ClearIcon className="text-red-500"/></span>
                                <span  className="text-justify text-purple-500 ">Tratamientos relacionados con la maternidad, embarazo, parto o cesárea</span>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3"><ClearIcon className="text-red-500"/></span>
                                <span className="text-justify text-purple-500 ">Gastos o asistencias que no sean consecuencia de una emergencia</span>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3"><ClearIcon className="text-red-500"/></span>
                                <span className="text-justify text-purple-500 ">Gastos o asistencias por prácticas deportivas, riñas, imprudencias de tráfico o intentos de suicidio</span>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3"><ClearIcon className="text-red-500"/></span>
                                <span className="text-justify text-purple-500 ">Servicios de traslado en ambulancia por tratamientos recurrentes y crónicos (ejemplo: diálisis, terapias).</span>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3"><ClearIcon className="text-red-500"/></span>
                                <span className="text-justify text-purple-500 ">Gastos, servicios o asistencias <b>excluidos de cobertura</b> en Condiciones Generales (como los relacionados con obesidad, oftalmología, odontología, medicina reproductiva, estética o plástica, embarazo, parto, cesárea, aborto, SIDA, adicciones, enfermedades psiquiátricas o trasplantes, entre otros)</span>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3"><ClearIcon className="text-red-500"/></span>
                                <span className="text-justify text-purple-500 ">Emergencias médicas ocasionadas por <b>eventos o causas excluidas de cobertura</b> en las Condiciones Generales (tales como operaciones bélicas, riesgos nucleares, catástrofes naturales, pandemias, disturbios multitudinarios, deportes, suicidio, adicciones o riñas, entre otras)</span>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3"><ClearIcon className="text-red-500"/></span>
                                <span className="text-justify text-purple-500 ">Chequeos médicos rutinarios</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="grid grid-flow-row auto-rows-max md:grid-rows-2 md:grid-cols-1 gap-4 mt-6 ">
                <div className="border-purple-200 border rounded-xl bg-purple-50  md:row-span-2 px-6 py-3">
                    <div className="flex flex-row items-center">
                        <img src={alert} alt="alert" className="w-12 h-12"/>
                        <h2 className="ml-6 font-bold text-2xl text-purple-500 ">¿Existen restricciones respecto a la cobertura?</h2>
                    </div>
                    <div className="mt-6">
                        <ul className="space-y-2 ml-10">
                            <li className="flex flex-row ">
                                <span className="mr-3 text-orange-500 font-bold">!</span>
                                <span className="text-justify text-purple-500 ">
                                    <b>Criterios de admisión para la cobertura de emergencias hospitalarias:</b> Edad máxima de 120 años <b>||</b> Gozar de buena salud y no haber padecido Cardiopatía Isquémica, Angina de Pecho, Infarto al Miocardio, Insuficiencia Cardíaca, Hipertensión Arterial o Diabetes Mellitus sin tratamiento médico y/o no controladas, ACV, Ataques de Isquemia Cerebral, Aneurisma, EBPOC, Insuficiencia renal crónica, Cáncer, Lupus, Esclerosis Múltiple y otras, según se determina en la póliza. <b>||</b> No consumir estupefacientes o psicotrópicos <b>||</b> Residir en el Ámbito Territorial del seguro <b>||</b> Ser titular de una membresía ASISTENSI
                                </span>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3 text-orange-500 font-bold">!</span>
                                <div>
                                    <span className="text-purple-500 ">Periodos de carencia: </span>
                                    <ul className="ml-10 list-disc space-y-2 ">
                                        <li className="text-orange-500">
                                            <span className="text-purple-500">24 horas para Orientación Virtual y Telefónica</span>
                                        </li>
                                        <li className="text-orange-500">
                                            <span className="text-purple-500">15 días para Atención Médica Domiciliaria, Pruebas de Laboratorios Clínicos y Emergencias Médicas en Centros de Salud (accidentes y determinadas enfermedades como fiebre reumática, apendicitis, bronquitis, gastroenteritis, abscesos, amigdalitis, otitis, parotiditis, rubéola, sarampión, varicela y otras).</span>
                                        </li>
                                        <li className="text-orange-500">
                                            <span className="text-purple-500">3 meses para las restantes emergencias médicas</span>
                                        </li>
                                        <li className="text-orange-500">
                                            <span className="text-purple-500">6 meses para los Beneficiarios que sufran de hipertensión arterial</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3 text-orange-500 font-bold">!</span>
                                <span  className="text-justify text-purple-500 ">
                                    Pruebas de laboratorio: máximo 2 al año
                                </span>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3 text-orange-500 font-bold">!</span>
                                <span  className="text-justify text-purple-500 "> Será necesaria autorización previa para el uso los servicios, salvo orientación médica telefónica</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="border-purple-200 border rounded-xl bg-purple-50  px-6 py-3">
                    <div className="flex flex-row items-center">
                        <img src={world} alt="world" className="w-116 h-16"/>
                        <h2 className="ml-6 font-bold text-2xl text-purple-500 ">¿Dónde estoy cubierto?</h2>
                    </div>
                    <div className="mt-6">
                        <ul className="space-y-2 ml-10">
                            <li className="flex flex-row">
                                <span className="mr-3"><CheckIcon className="text-purple-450"/></span>
                                <span className="text-justify text-purple-500 ">Los servicios sólo se prestarán a través del Sistema Coordinado de Proveedores Asistensi en el Ámbito Territorial del seguro que incluye las siguientes ciudades de la República Bolivariana de Venezuela: Caracas, Gran Caracas, Barcelona, Barquisimeto, Cagua, Charallave, Guacara, Guarenas, Guatire, La Victoria, Lechería, Los Teques, Maracaibo, San Francisco, Maracay, Pampatar, Porlamar, Puerto La Cruz, Puerto Ordáz, San Antonio de Los Altos, San Cristóbal, Turmero, Valencia y Vargas</span>
                            </li>
                            <li className="flex flex-row">
                                <span className="mr-3"><CheckIcon className="text-purple-450"/></span>
                                <span className="text-justify text-purple-500 ">Las emergencias hospitalarias sólo están cubiertas en los centros del Sistema Coordinado de Proveedores Asistensi del Ámbito Territorial del seguro</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="border-purple-200 border rounded-xl bg-purple-50  px-6 py-3">
                    <div className="flex flex-row items-center">
                        <img src={hand} alt="hands" className="w-12 h-12"/>
                        <h2 className="ml-6 font-bold text-2xl text-purple-500 ">¿Cuáles son mis obligaciones?</h2>
                    </div>
                    <div className="mt-6">
                        <ul className="ml-14 list-disc space-y-2">
                            <li className="text-green-300">
                                <span className="text-purple-500 ">Cumplimentar el cuestionario de salud con información veraz, exacta y completa </span>
                            </li>
                            <li className="text-green-300">
                                <span className="text-purple-500 ">Pagar la prima en los términos convenidos </span>
                            </li>
                            <li className="text-green-300">
                                <span className="text-purple-500 ">En caso de emergencia, emplear todos los medios a su alcance para minorar sus consecuencias </span>
                            </li>
                            <li className="text-green-300">
                                <span className="text-purple-500 ">Solicitar la prestación de los servicios según el procedimiento previsto en la póliza</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-3">
                <div className="flex flex-col">
                    <div className="border-purple-200 border rounded-xl bg-purple-50  px-6 py-3">
                        <div className="flex flex-row items-start">
                            <img src={euro} alt="euro" className="w-12 h-12"/>
                            <div className="ml-6">
                                <h2 className="font-bold text-2xl text-purple-500 ">¿Cuándo y cómo tengo que efectuar los pagos?</h2>
                                <p className="mt-5 text-justify -ml-5 text-purple-500 ">El primer pago se realizará de inmediato. Los restantes dependerán de la modalidad de pago elegida (anual, semestral, trimestral o mensual). Los medios de pago disponibles son: tarjeta de crédito/débito, Paypal, Bizum, domiciliación bancaria o transferencia bancaria.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="border-purple-200 border rounded-xl bg-purple-50 px-6 py-3">
                        <div className="flex flex-row items-start">
                            <img src={clock} alt="clock" className="w-12 h-12"/>
                            <div className="ml-6">
                                <h2 className="font-bold text-2xl text-purple-500 ">¿Cuándo comienza y finaliza la cobertura?</h2>
                                <p className="mt-5 -ml-5 text-justify text-purple-500 ">Comienza en la fecha de pago de la prima (sujeto a los periodos de carencia) y dura un año, siempre y cuando abone los pagos conforme a la modalidad de pago seleccionada. La cobertura es renovable de por vida, siempre y cuando la póliza se haya mantenido activa sin interrupciones.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="border-purple-200 border rounded-xl bg-purple-50  px-6 py-3">
                        <div className="flex flex-row items-start">
                            <img src={stop} alt="stop" className="w-12 h-12"/>
                            <div className="ml-6">
                                <h2 className="font-bold text-2xl text-purple-500 ">¿Cómo puedo rescindir el contrato?</h2>
                                <p className="mt-5 -ml-5 text-justify text-purple-500 ">Mediante el envío de una notificación por escrito a la aseguradora en el plazo de 30 días, en los términos previstos en la póliza. Es posible que, en determinados supuestos, no tenga usted derecho a ninguna devolución. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-20 space-y-5">
                <div className="bg-purple-500 rounded-xl flex flex-row justify-between items-center  px-10 py-5 ">
                    <div className="flex-1 uppercase font-bold text-2xl ">
                        <h1 className="text-white">INFORMACIÓN DEL MEDIADOR</h1>
                        <h2 className="-mt-2 text-pink-700">MAYTOWER, S.L.</h2>
                    </div>
                    <div className="w-1/3 ">
                         <img src={logo} alt="asistensi_logotipo" className="md:px-6 max-w-lg h-auto" />
                    </div>
                </div>
                <div className="bg-purple-50 rounded-xl px-6 py-5 text-purple-500 ">
                    <p className="text-justify py-3">
                        Conforme al artículo 173 del Real Decreto-Ley 03/2020, a continuación se proporciona la información del mediador de seguros que distribuye los seguros de asistencia sanitaria <b>“PLAN ASISTENSI”</b> y <b>“PLAN ASISTENSI PLUS”</b>:
                    </p>
                    <hr className="border-purple-500"/>
                    <div className="mt-2">
                        <div className="flex flex-col py-3">
                            <p className="w-full font-bold ">Mediador:</p>
                            <div className=" flex text-justify">
                                <div className="flex-col">
                                    <p>
                                        Maytower S.L. | Agencia de seguros exclusiva de la entidad aseguradora <b>ASISTENCIA CLÍNICA UNIVERSITARIA DE NAVARRA, S.A. DE SEGUROS Y REASEGUROS (“ACUNSA”)</b>
                                    </p>
                                    <p className="mt-1">
                                    <span>
                                        Calle Almagro, 3 - Madrid 28010 <b>(<a href="mailto:europa@asistensi.com" className="underline cursor-pointer text-pusrple-500">europa@asistensi.com</a>)
                                        </b>
                                    </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr className="border-purple-500"/>
                        <div className="flex flex-col py-3">
                            <p className="w-full"><b>Asesoramiento: </b>  No se ofrece.</p>
                        </div>
                        <hr className="border-purple-500"/>
                        <div className="flex flex-col py-3">
                            <p className="w-full font-bold">Quejas y reclamaciones:</p>
                            <div className=" flex text-justify">
                                <div className="text-justify">
                                    <span>
                                    Usted podrá presentar quejas relacionadas con la actividad de distribución de seguros desarrollada por Maytower, S.L. ante el Servicio de Atención al Cliente de ACUNSA:<br/>
                                    </span>
                                    <span className="break-all">
                                    en soporte papel, en la siguiente dirección; Avenida Pío XII, 57 (31008) Pamplona; o,<br/> por medios informáticos al correo electrónico: info@acunsa.es, especificando que se dirige a la atención<br/> del Departamento de Atención al Cliente, siempre acreditando la identidad del solicitante.<br/> Además, usted podrá presentar quejas y reclamaciones, relacionadas con sus intereses y derechos <br/>legalmente reconocidos, ante el Servicio de Reclamaciones de la Dirección General de Seguros y Fondos <br/>de Pensiones. Para más información, consulte el siguiente enlace:  <br/> {' '}
                                        <a href="http://www.dgsfp.mineco.es/es/Consumidor/Reclamaciones" target="_blank" className="text-purple-500 font-bold underline cursos-pointer break-words">www.dgsfp.mineco.es/es/Consumidor/Reclamaciones</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr className="border-purple-500"/>
                        <div className="flex flex-col py-3">
                            <p className="w-full font-bold">Inscripción:</p>
                            <div className="flex text-justify">
                                <p>
                                    Maytower S.L. consta inscrito como Agencia de seguros exclusiva de ACUNSA en el Registro Administrativo de Distribuidores de Seguros y Reaseguros de la Dirección General de Seguros y Fondos de Pensiones con la clave de inscripción C0325B88532742. Puede comprobar la inscripción a través del siguiente enlace:{' '}
                                    <a href="http://rrpp.dgsfp.mineco.es/Mediador" target="_blank" className="text-purple-500 font-bold underline cursos-pointer">http://rrpp.dgsfp.mineco.es/Mediador </a>
                                </p>
                            </div>
                        </div>
                        <hr className="border-purple-500"/>

                        <div className="flex flex-col py-3">
                            <p className="w-full font-bold">Representación y vinculación:</p>
                            <div className=" flex text-justify">
                                <p>
                                    Maytower, S.L., en su condición de agencia de seguros exclusiva de ACUNSA, actúa únicamente en representación de esta. Maytower, S.L. posee más de un 10% del capital de una entidad aseguradora distinta de ACUNSA.
                                </p>
                            </div>
                        </div>
                        <hr className="border-purple-500"/>
                        <div className="flex flex-col py-3">
                            <p className="w-full font-bold">Remuneración:</p>
                            <div className=" flex text-justify">
                                <p>
                                    Por sus labores de distribución de seguros, Maytower, S.L. percibe una remuneración en forma de comisiones sobre primas de seguro.
                                </p>
                            </div>
                        </div>
                        <hr className="border-purple-500"/>
                        <div className="flex flex-col py-3">
                            <p className="w-full font-bold">Protección de datos:</p>
                            <div className=" flex text-justify">
                                <p>
                                    Maytower S.L., Agencia de seguros exclusiva de la entidad aseguradora ACUNSA, actúa como Encargado del Tratamiento de sus datos por cuenta y bajo las instrucciones de ACUNSA de acuerdo con su política de privacidad, siendo ACUNSA responsable del tratamiento. Puede encontrar más información sobre el tratamiento de sus datos personales en las condiciones generales del plan escogido y/o en el sitio web<br/> <a href="http://www.acunsa.es/rgpd" target="_blank" className="text-purple-500 font-bold underline cursos-pointer">www.acunsa.es/rgpd</a>.
                                </p>
                            </div>
                        </div>
                        <hr className="border-purple-500"/>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default withMobileDialog({ breakpoint: "xs" })(TermsDIP);
