import React, { Component } from "react";
import PropTypes from "prop-types";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import TermsConditionPrivate from "components/terms/TermsConditionPrivate";
import TermsConditionAlly from "components/terms/TermsConditionAlly";
import TermsConditionAllyPDF from "components/terms/TermsConditionAllyPDF";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import TermsConditionAcunsa from "./TermsConditionAcunsa";

const logo = "/static/images/Asistensi_logotipo.svg";

class TermsConditionDialog extends Component {
  render() {
    const { fullScreen } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth="md"
        onClose={this.props.handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        fullScreen={fullScreen}
      >
        <DialogTitle className="text-gray-900 font-bold">
          <img
            loading="lazy"
            src={logo}
            alt="asistensi_logotipo"
            width="160px"
          />
        </DialogTitle>
        <DialogContent>
          {this.props.is_ally === true ? (
            this.props.user_ally.user_type == 110 &&
            this.props.user_ally.terms_condition_pdf_agency ? (
              <TermsConditionAllyPDF
                pdf_tc={this.props.user_ally.terms_condition_pdf_agency}
              />
            ) : this.props.user_ally.user_type == 100 &&
              this.props.user_ally.terms_condition_pdf_agent ? (
              <TermsConditionAllyPDF
                pdf_tc={this.props.user_ally.terms_condition_pdf_agent}
              />
            ) : (
              <TermsConditionAlly />
            )
          ) : this.props.isACUNSA ? (
              <TermsConditionAcunsa />
          ) : (
            <TermsConditionPrivate />
          )}
          {(this.props.is_affiliation === true ||
            this.props.is_ally === true) && (
            <div className="flex justify-evenly my-10">
              <button
                className="secondary-button-nav mr-2"
                onClick={() => this.props.handleClose(false, "acceptTerms")}
              >
                No acepto
              </button>
              <button
                className="white-button-nav"
                onClick={() => this.props.handleClose(true, "acceptTerms")}
              >
                Acepto
              </button>
            </div>
          )}
        </DialogContent>
        {this.props.is_affiliation !== true && (
          <DialogActions>
            <button
              className="secondary-button-nav"
              onClick={this.props.handleClose}
            >
              Cerrar
            </button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

TermsConditionDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog({ breakpoint: "xs" })(TermsConditionDialog);
