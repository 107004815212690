import React from 'react';

const PromoTerms = () => {

  return (
      <div className="w-full">
        <div>
          <p className="text-center my-4">
            <b>TÉRMINOS Y CONDICIONES DE LA PROMOCIÓN</b>
            <br />
            <b>“Botón de Asistencia”</b>
          </p>
        </div>
        <div className="px-4">
          <p className="my-4">
            Mediante  las  bases  que  a  continuación  se  relacionan,  se  establecen  las  normas reguladoras ofrecidas por Asistensi Global Insurance, Inc. de la promoción comercial que se ofrece a los beneficiarios en las condiciones que se detallan en los presentes Términos y Condiciones (la “<b>PROMOCIÓN</b>”).
          </p>
          <p className="my-4">
            La empresa Asistensi Global Insurance, Inc. es una compañía constituida bajo las leyes del Barbados, con domicilio en Level 3, Cedar Court, Wildey Business Park, Wildey, St. Michael,  BB14006,  Barbados,  inscrita  en  el  Registro  Mercantil  de  Barbados  bajo  el número de sociedad 44330 y cuyas filiales, socios y otras partes vinculadas se dedican a la  comercialización  de  seguros,  productos  y  servicios  de  salud.  Todas  las  menciones  a “<b>ASISTENSI</b>”  en  los  presentes  Términos  y  Condiciones  incluirán  todas  las  compañías subsidiarias   y   afiliadas   de   Asistensi   Global   Insurance,   Inc.   que   se   dediquen   a comercializar seguros, productos y servicios de salud, junto con socios comerciales, bajo la marca “Asistensi”.
          </p>
          <div className="my-4">
            <p className="font-bold">1.- Objeto de la Promoción</p>
            <p className="my-4">
              El  objeto  de  la  PROMOCIÓN  es  ofrecer  un  (1)  botón  de  asistencia  (el  “<b>BOTÓN  DE ASISTENCIA</b>”) de manera gratuita durante un (1) año para aquellos INTERESADOS, tal y como  este  término  se  define  en  la  cláusula  2  abajo,  que  formalicen  el  pago  en  su modalidad anual de dos o más planes ofrecidos por ASISTENSI, tanto en su modalidad PLAN ASISTENSI como en PLAN ASISTENSI PLUS (el “<b>PLAN</b>” o “<b>PLANES</b>”) para dos o más BENEFICIARIOS de más de 65 años de edad, entre los días 1 de enero de 2022 a las 00:00 horas  (GMT-4)  y  el  31  de  enero  de  2022  a  las  23:59  horas  (GMT-4)  (el  “<b>PERIODO  DE DURACIÓN</b>”). Será el INTERESADO el encargado de asignar el BOTÓN DE ASISTENCIA al BENEFICIARIO de su elección.
            </p>
            <p className="my-4">
              Terminado  el  año  en  el  que  el  BOTÓN  DE  ASISTENCIA  es  gratuito,  el  INTERESADO decidirá si quedarse con el botón de asistencia, pagando el costo anual de dicho botón o devolver el botón de asistencia sin coste alguno.
            </p>
            <p className="my-4">
              EL BOTÓN DE ASISTENCIA es personal e intransferible, no será susceptible de cambio, alteración o compensación a petición de los participantes de la presente PROMOCIÓN.
            </p>
          </div>
          <div className="my-4">
            <p className="font-bold">2.- Participantes</p>
            <p className="my-4">
              Podrán participar en la presente PROMOCIÓN, aquellas personas físicas elegibles para la  contratación  o  renovación  del  PLAN  en  virtud  de  los  términos  y  condiciones  de  los PLANES y que lo contraten a través de la página web <a className="text-blue underline" href="https://www.asistensi.com/" target="_blank">www.asistensi.com</a> durante el PERIODO DE DURACIÓN (los “<b>INTERESADOS</b>”).
            </p>
            <p className="my-4">
              Los INTERESADOS tendrán que formalizar la contratación de los PLANES, incluyendo el pago de la prima en su modalidad anual, durante el PERIODO DE DURACIÓN, por lo que no  se  aplicará  la  PROMOCIÓN  a  aquellos  que  no  terminen  el  proceso  de  contratación durante dicho PERIODO DE DURACIÓN.
            </p>
          </div>
          <div className="my-4">
            <p className="font-bold">3.- Duración y Ámbito Territorial</p>
            <p className="my-4">
              La  PROMOCIÓN  tendrá  la  vigencia  del  PERIODO  DE  DURACIÓN  y  será  aplicable  para aquellos PLANES contratados en la República Bolivariana de Venezuela.
            </p>
            <p className="my-4">
              Se procederá a entregar un BOTÓN DE ASISTENCIA por cada uno de los INTERESADOS que  contraten  dos  o  más  PLANES  durante  el  mes  de  enero  de  2022.  En  caso  de  que ASISTENSI no consiguiese localizar al INTERESADO para hacerle entrega del BOTÓN DE ASISTENCIA transcurridos 90 días después la conclusión del PERIODO DE DURACIÓN, se entenderá que rechaza la PROMOCIÓN.
            </p>
          </div>
          <div className="my-4">
            <p className="font-bold">4.- Mecánica de la Promoción</p>
            <p className="my-4">
              La  participación  en  la  PROMOCIÓN  es  gratuita  y  voluntaria  y  supone  la  aceptación expresa y sin reservas de las presentes bases por parte de los INTERESADOS. En caso de no  aceptar  las  presentes  bases  deberá  expresarlo  enviando  su  respuesta  al  correo electrónico <a target="_blank" href="mailto:legal@asistensi.com" className="underline cursor-pointer text-blue">legal@asistensi.com</a>
            </p>
            <p className="my-4">
              Si  a  cualquiera  de  los  INTERESADOS  le  resultara  de  aplicación  alguna  de  las  causas  de exclusión relacionadas en la condición quinta de los presentes términos y condiciones, no podrá acceder a la PROMOCIÓN.
            </p>
            <p className="my-4">
              La  aceptación  de  la  PROMOCIÓN  por  los  INTERESADOS  es  de  carácter  voluntario, renunciando a cualquier reclamación contra ASISTENSI por tal motivo.
            </p>
          </div>

          <ul className="my-4">
            <b>5.- Exclusiones,</b>
            <li className="my-4">
              5.1. Que el INTERESADO sea menor de 18 años y, en general, que no sea elegible para la contratación  o  renovación  del  PLAN  en  virtud  de  los  términos  y  condiciones  de  los PLANES.
            </li>
            <li className="my-4">
              5.2.  Que  el  INTERESADO  cancele  los  PLANES  en  los  tres  (3)  meses  siguientes  a  la contratación.
            </li>
            <li className="my-4">
              5.3. Que el INTERESADO cancele un PLAN vigente con la intención de volver a contratar el mismo PLAN para el mismo BENEFICIARIO y acceder a la PROMOCIÓN.
            </li>
            <li className="my-4">
              5.4. Que el INTERESADO contrate menos de dos PLANES.
            </li>
            <li className="my-4">
              5.5.  Que  el  INTERESADO  contrate  los  PLANES  para  menos  de  dos  BENEFICIARIOS menores de 65 años.
            </li>
            <li className="my-4">
              5.6.  Que  el  INTERESADO  no  elija  la  modalidad  de  pago  anual  para  los  PLANES contratados.
            </li>
          </ul>

          <div className="my-4">
            <p className="font-bold">6.- Modificaciones y/o anexos</p>
            <p className="my-4">
              ASISTENSI se reserva el derecho a realizar modificaciones, añadir anexos sucesivos y, en general, realizar cuantos cambios redunden en el buen fin de la PROMOCIÓN, así como en  la  organización  y/o  gestión  de  la  misma,  cuando  concurran  causas  que  así  lo justifiquen, no perjudiquen a los participantes y se comuniquen a éstos debidamente a través de su correo electrónico.
            </p>
          </div>

          <div className="my-4">
            <p className="font-bold">7.- Cancelación</p>
            <p className="my-4">
              ASISTENSI   se   reserva   igualmente   el   derecho   a   cancelar   parcial   o   totalmente   la PROMOCIÓN,  si  fuera  necesario,  por  causa  de  fuerza  mayor  o  por  cualesquiera  otras causas que resultasen ajenas a su voluntad.
            </p>
          </div>

          <div className="my-4">
            <p className="font-bold">8.- Derecho de eliminación de participaciones fraudulentas</p>
            <p className="my-4">
              ASISTENSI  se  reserva  el  derecho  de  eliminar  justificadamente  a  cualquier  participante que  defraude,  altere  o  inutilice  el  buen  funcionamiento  y  el  transcurso  normal  y reglamentario de la PROMOCIÓN en su conjunto.
            </p>
          </div>

          <div className="my-4">
            <p className="font-bold">9.- Interpretación</p>
            <p className="my-4">
              Toda la dificultad que surja o nazca a partir de la aplicación o de la interpretación de las presentes  condiciones,  será  resuelta  en  última  instancia  por  la  organización  de  la PROMOCIÓN.
            </p>
          </div>

          <div className="my-4">
            <p className="font-bold">10.- Protección de Datos de Carácter Personal</p>
            <p className="my-4">
              De conformidad con la normativa vigente de protección de datos, los datos personales facilitados voluntariamente a ASISTENSI en relación con la presente PROMOCIÓN serán tratados por ASISTENSI en calidad de Responsable del Tratamiento, con domicilio social en Barbados.
            </p>

            <p className="my-4">
              La  finalidad  del  tratamiento  de  sus  datos  es  la  de  gestionar  su  participación  en  la PROMOCIÓN   de   acuerdo   con   estas   bases.   Esta   finalidad   está   basada   en   su consentimiento para participar en la PROMOCIÓN.
            </p>

            <p className="my-4">
              El  tratamiento  de  sus  datos  es  obligatorio  para  gestionar  la  presente  PROMOCIÓN. Negarse a facilitarlos impedirá la participación en la misma.
            </p>

            <p className="my-4">
              Podrá ejercitar en cualquier momento sus derechos de acceso, rectificación, supresión y portabilidad, de limitación y oposición a su tratamiento ante ASISTENSI en la dirección de  correo  electrónico <a target="_blank" href="mailto:dpo@asistensi.com" className="text-blue underline cursor-pointer">dpo@asistensi.com</a>,  en  ambos  casos  incluyendo  en  el  asunto  la referencia: “Protección de Datos” y aportando fotocopia de su identificación.
            </p>

            <p className="my-4">
              Puede   consultar   la   información   adicional   sobre   nuestra   política   de   privacidad en <a className="text-blue underline" href="https://www.asistensi.com/" target="_blank">www.asistensi.com</a>
            </p>
          </div>

          <span className="mt-6 font-bold flex justify-end">31 de diciembre de 2021</span>
        </div>
      </div>
  )
}

export default PromoTerms;