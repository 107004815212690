import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import PromoTerms from './PromoTerms';

const logo = '/static/images/Asistensi_logotipo.svg';

const PromoTermDialog = (props) => {
  const { fullScreen, open, handleClose, goToHomeQuotation } = props;

  return (
    <>
      <style>
        {`
          .MuiDialogActions-root {
            justify-content: space-between;
          }
        `}
      </style>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
        // disableBackdropClick={true}
        // disableEscapeKeyDown={true}
        fullScreen={fullScreen}
      >
        <DialogTitle className="text-gray-800 font-bold">
          <img src={logo} alt='Asistensi_logotipo' className="h-12 w-auto" />
        </DialogTitle>
        <DialogContent>
          <PromoTerms />
        </DialogContent>
        <DialogActions>
          <button
            className="secondary-button-nav"
            onClick={handleClose}
          >
            Cerrar
          </button>

          <button
            className="primary-button w-40 h-12 m-l10"
            onClick={() => {
              goToHomeQuotation();
              handleClose();
            }}
          >
            Cotizar
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}


PromoTermDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog({breakpoint: 'xs'})(PromoTermDialog);