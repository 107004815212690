import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

const logo = '/static/images/Asistensi_logotipo.svg';

function VoluntaryTerms(props) {
  return (
    <Dialog
      open={props.open}
      maxWidth="md"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={props.fullScreen}
    >
      <DialogTitle>
        <img loading="lazy" src={logo} alt='Asistensi logotipo' className="w-auto h-12" />
      </DialogTitle>
      <DialogContent>
        <div className="w-full text-base leading-relaxed m-auto">
          <h3 className="font-bold text-center text-base">
            TÉRMINOS Y CONDICIONES DE USO RED DE VOLUNTARIOS ASISTENSI SOS
          </h3>
          <p className="my-4">
            Para continuar con el proceso de registro en la <b>RED DE VOLUNTARIOS ASISTENSI SOS</b> es obligatorio leer, estar de acuerdo y aprobar los siguientes términos y condiciones de uso que usted, en lo adelante denominado <b>EL VOLUNTARIO</b>, suscribe con <b>ASISTENSI GLOBAL INSURANCE INC</b>, en adelante denominado <b>ASISTENSI</b>. Este acuerdo contiene los términos y condiciones de uso para formar parte de la red de voluntarios de la plataforma de telemedicina <b>ASISTENSI SOS</b>, en lo adelante denominado <b>PLATAFORMA</b>.
          </p>
          <p className="my-4">
            El uso de la <b>PLATAFORMA</b> está condicionado a la aceptación por parte del <b>VOLUNTARIO</b>, de los presentes términos y condiciones de uso descritos a continuación. Por favor, lea atentamente el presente documento antes de utilizar la <b>PLATAFORMA</b>. Al acceder y utilizar la <b>PLATAFORMA</b>, usted expresa su conocimiento y aceptación de los presentes términos y condiciones de uso. 
          </p>
          <p className="text-center font-bold">
            ***SI NO ESTÁ DE ACUERDO CON ESTOS TÉRMINOS Y CONDICIONES, NO UTILICE LA PLATAFORMA***
          </p>
          <p className="font-bold my-4">
            TÉRMINOS Y CONDICIONES
          </p>
          <p className="my-4">
           Los presentes términos y condiciones de uso son de aplicación para todos los servicios de salud provistos a través de la <b>PLATAFORMA</b>.
          </p>
          <p className="font-bold my-4">DEFINICIONES Y CONCEPTOS</p>
          <p className="my-4">
            <b>VOLUNTARIO:</b> Persona natural, de nacionalidad venezolana, graduado y debidamente licenciado como médico cirujano, inscrito en un colegio de médicos y apto para ejercer la medicina de conformidad con las leyes aplicables en la República Bolivariana de Venezuela.
          </p>
          <p className="my-4">
            <b>BENEFICIARIO:</b> Persona natural, de nacionalidad venezolana que usa a la plataforma para acceder a orientación médica remota.
          </p>
          <p className="my-4">
            <b>ORIENTACIÓN MÉDICA REMOTA:</b> son aquellas recomendaciones médicas telefónicas hechas al <b>BENEFICIARIO</b>, al comunicarse con un <b>VOLUNTARIO</b> a través de la <b>PLATAFORMA</b>. El alcance de las recomendaciones dependerá de la condición (patología) indicada por el <b>BENEFICIARIO</b> y siempre será considerada una impresión diagnóstica sin que esto signifique un diagnóstico o tratamiento definitivo.
          </p>
          <p className="font-bold my-4">OBJETO DEL ACUERDO</p>
          <p className="my-4">
            Los presentes términos y condiciones, regulan y definen la relación entre los <b>VOLUNTARIOS</b> y <b>ASISTENSI</b>, y el uso por parte de los <b>VOLUNTARIOS</b> de la <b>PLATAFORMA</b>, la cual es propiedad de <b>ASISTENSI</b> y es administrada en beneficio de los <b>USUARIOS</b>.
          </p>
          <p className="font-bold my-4">REGISTRO COMO VOLUNTARIO</p>
          <p className="my-4">
            Para tener acceso a la <b>PLATAFORMA</b> como <b>VOLUNTARIO</b>, usted deberá solicitar la inclusión y creación de su usuario, suministrando de forma completa la información y datos personales solicitados. Usted acuerda no crear más de una cuenta de usuario, ni crear una cuenta para ninguna otra persona. Asimismo, usted se compromete a proveer información correcta, verdadera y actualizada de su persona al momento de registrarse o al momento de hacer cualquier actualización de su ficha de información personal. En caso de proveer información falsa o incorrecta nos reservamos el derecho de suspender su cuenta y negarle el acceso al uso de la <b>PLATAFORMA</b>. <b>ASISTENSI</b> se reserva el derecho de ejercer todas las acciones a que pueda haber lugar en caso de sufrir algún daño por este supuesto.
          </p>
          <p className="font-bold my-4">SEGURIDAD DE LA INFORMACIÓN</p>
          <p className="my-4">
            Al registrarse, usted recibirá un usuario y clave de acceso, ambos personales e intransferibles. Usted es responsable de mantener la seguridad de su cuenta, cuidando en todo momento de la seguridad y confidencialidad de sus claves de acceso e información médica personal. Usted nunca deberá permitir a nadie acceder a su cuenta en su nombre y asume la responsabilidad de cualquier uso no autorizado de su cuenta por parte de terceras personas. En caso de que la seguridad de su cuenta se vea comprometida, usted deberá notificar a <b>ASISTENSI</b>, de manera inmediata.
          </p>
          <p className="font-bold my-4">OBLIGACIÓN DE REGISTRO DE CONSULTAS REALIZADAS EN LA PLATAFORMA</p>
          <p className="my-4">
            <b>El VOLUNTARIO</b> está obligado a cargar adecuadamente toda la información requerida por la <b>PLATAFORMA</b> en relación a las orientaciones médicas realizadas en el marco de su actividad como <b>VOLUNTARIO</b>.
          </p>
          <p className="font-bold my-4">INDEPENDENCIA DE CRITERIO PROFESIONAL</p>
          <p className="my-4">
            Los <b>VOLUNTARIOS</b> ofrecen sus servicios en el libre ejercicio de su profesión, bajo la guía de protocolos estándares y mundialmente aceptados acerca del manejo de situaciones de salud <u>y en el mejor interés de los <b>BENEFICIARIOS</b></u>. Cualquier información, recomendación, indicación, diagnóstico, prescripción o tratamiento impartido por un <b>VOLUNTARIO</b>, proviene exclusivamente de ese profesional <b>y no</b> de <b>ASISTENSI</b>, la cual en ningún caso será responsable por daños o perjuicios relacionados o derivados de diagnósticos, tratamientos o asesoría médica impartida por <b>VOLUNTARIOS</b>, ni por ningún daño ni perjuicio relacionado con cualquier otra forma de información obtenida a través de la <b>PLATAFORMA</b>. Los <b>VOLUNTARIOS</b> serán los únicos responsables por los diagnósticos o informaciones compartidas a través de la <b>PLATAFORMA</b>.
          </p>
          <p className="font-bold my-4">EXCLUSIÓN LABORAL</p>
          <p className="my-4">
            Todas las actividades realizadas por el <b>VOLUNTARIO</b> a través de la <b>PLATAFORMA</b>, son realizadas de manera totalmente gratuita y altruista en su condición de <b>VOLUNTARIO</b>, se entiende que no existe ni existirá una relación laboral entre <b>ASISTENSI</b> y los <b>VOLUNTARIOS</b>. En consecuencia, <b>ASISTENSI</b> no tendrá obligación de pagar salario, contraprestación ni remuneración alguna a los <b>VOLUNTARIOS</b> que pueden entenderse derivadas de una relación laboral.
          </p>
          <p className="font-bold my-4">PROHIBICIÓN DE USO ILEGAL O INDEBIDO</p>
          <p className="my-4">Expresamente el <b>VOLUNTARIO</b> se compromete a:</p>
          <ul>
            <li className="ml-8 list-decimal">
              No ingresar a la <b>PLATAFORMA</b> virus ni códigos maliciosos de ningún tipo.
            </li>
            <li className="ml-8 list-decimal">
              No solicitar información de inicio de sesión ni acceder a una cuenta perteneciente a otro usuario.
            </li>
            <li className="ml-8 list-decimal">
              No utilizar la <b>PLATAFORMA</b> para actos ilícitos, engañosos, malintencionados o discriminatorios.
            </li>
            <li className="ml-8 list-decimal">
              No realizar ninguna acción que pudiera inhabilitar, sobrecargar o afectar al funcionamiento correcto de la <b>PLATAFORMA</b> o su aspecto.
            </li>
            <li className="ml-8 list-decimal">
              No proporcionar nombres y datos falsos.
            </li>
            <li className="ml-8 list-decimal">
              No proporcionar información personal falsa, ni crear una cuenta para otras personas sin su autorización.
            </li>
            <li className="ml-8 list-decimal">
              No crear más de una cuenta personal.
            </li>
            <li className="ml-8 list-decimal">
              No utilizar la <b>PLATAFORMA</b> si es menor de 18 años. 
            </li>
            <li className="ml-8 list-decimal">
              No transferir la cuenta de usuario que administre a nadie.
            </li>
            <li className="ml-8 list-decimal">
              No publicar contenido ni realizar ninguna acción en la <b>PLATAFORMA</b> que infrinja o vulnere los derechos de terceros o que vulnere cualesquier ley de algún modo.
            </li>
            <li className="ml-8 list-decimal">
              No utilizar o guardar la data de los <b>BENEFICIARIOS</b> para uso personal o comercial por ningún motivo en ningún momento.
            </li>
            <li className="ml-8 list-decimal">
              No contactar a los <b>BENEFICIARIOS</b> fuera del ámbito de actuación de la atención de una consulta a través de la orientación médica a distancia.
            </li>
          </ul>
          <p className="my-4">
            Todo lo enumerado en el párrafo anterior se hace a titulo enunciativo y no limitativo, en general el <b>VOLUNTARIO</b> se compromete a no utilizar la <b>PLATAFORMA</b> para ninguna finalidad que sea ilegal o esté prohibida por estos términos de uso.
          </p>
          <p className="font-bold my-4">PROPIEDAD INTELECTUAL</p>
          <p className="my-4">
            Es entendido que los logos, lemas, colores, marcas, sellos distintivos, certificados de calidad, software, certificaciones gremiales, principales logros y/o características diferenciadoras de la <b>PLATAFORMA</b> están protegidos por las leyes de propiedad intelectual pertinentes y son propiedad exclusiva de <b>ASISTENSI</b>.
          </p>
          <p className="font-bold my-4">CONFIDENCIALIDAD</p>
          <p className="my-4">
            El <b>VOLUNTARIO</b> se compromete a mantener bajo estricta confidencialidad toda la información de los <b>BENEFICIARIOS</b> que obtenga durante su interacción con ellos a través de la <b>PLATAFORMA</b> o la llamada para la orientación  médica a distancia. <b><u>Adicionalmente, el VOLUNTARIO se compromete a eliminar de sus correos electrónicos, computadores o cualquier medio de almacenamiento toda la información de los BENEFICIARIOS a los cuales el VOLUNTARIO haya tenido acceso en un período menor a 24 horas desde la recepción de dicha información</u></b>. El incumplimiento del deber de confidencialidad contemplado en la presente cláusula tiene consecuencias penales y civiles, de las cuales será enteramente responsable el <b>VOLUNTARIO</b>.
          </p>
          <p className="font-bold my-4">POLITICA DE PRIVACIDAD DE LA DATA</p>
          <p className="my-4">
            Sin perjuicio de la obligación de confidencialidad establecida ut supra, el <b>VOLUNTARIO</b> se obliga a cumplir cabalmente con la <b>política de privacidad de ASISTENSI SOS</b>, la cual se transcribe a continuación:
          </p>
          <p className="my-4">
            A excepción de lo establecido más adelante, toda información que relacione la identidad del <b>BENEFICIARIO</b> con las condiciones físicas y mentales presentes, pasadas o futuras y su historia médica, es considerada información de carácter privado y tratada como tal. <b>ASISTENSI</b>, garantiza la confidencialidad médico (“Profesional de la salud”) - paciente <b>(BENEFICIARIO)</b> que exige las leyes de la República Bolivariana de Venezuela.
          </p>
          <p className="my-4">
            Como parte de su proceso operativo, registra información personal del <b>BENEFICIARIO</b> y se obliga a no venderla, licenciarla o compartirla fuera de <b>ASISTENSI</b> y sus entidades asociadas excepto:
          </p>
          <ul>
            <li className="ml-8 list-decimal">
              Si el <b>BENEFICIARIO</b> autoriza expresamente a hacerlo
            </li>
            <li className="ml-8 list-decimal">
              Si es necesario para permitir a nuestros profesionales de servicios médicos, entidades asociadas o agentes, proveerle servicios al <b>BENEFICIARIO</b> en nuestro nombre
            </li>
            <li className="ml-8 list-decimal">
              Solo con el fin de proveerle nuestros productos o servicios o aquellos de nuestras entidades asociadas
            </li>
            <li className="ml-8 list-decimal">
              Solo para suministrarla a entidades que realicen servicios de mercadeo y promoción para nosotros o en nuestro nombre, o a entidades con las cuales tengamos alianzas de mercadeo
            </li>
            <li className="ml-8 list-decimal">
              Cuando sea necesario con motivo de la venta de la totalidad o una mayoría sustancial de los activos de <b>ASISTENSI</b>, o su fusión o reorganización con otra entidad
            </li>
            <li className="ml-8 list-decimal">
              Cuando sea requerido o permitido por la Ley
            </li>
            <li className="ml-8 list-decimal">
              Cuando el <b>BENEFICIARIO</b> acceda a nuestro servicio o <b>PLATAFORMA</b> a través convenios suscritos entre <b>ASISTENSI</b>, y compañías aseguradoras, administradoras de salud o similares. A estos efectos, se considera como información personal, los nombres y apellidos, la edad, la afección o dolencia sufrida, la medicación recetada, el tiempo de recuperación, el correo electrónico y los teléfonos de contacto del <b>BENEFICIARIO</b> y sus dependientes.
            </li>
          </ul>
          <p className="my-4">
            <b>ASISTENSI</b> se reserva el derecho de utilizar data anónima proveniente de informaciones generadas y almacenadas durante el proceso de interacción entre <b>BENEFICIARIO</b>, sus dependientes y el “Profesional de la salud” con el fin de proveer información estadística a terceros.
          </p>
          <p className="font-bold my-4">EXCLUSIÓN DE RESPONSABILIDAD</p>
          <p className="my-4">
            El <b>VOLUNTARIO</b> se compromete, a su propia costa, a defender y mantener indemne a <b>ASISTENSI</b> por cualquier reclamación, juicio, o demanda interpuesta en su contra como consecuencia de la ejecución de su voluntariado. La obligación prevista en la presente cláusula no se agotará una vez terminado el voluntariado. 
          </p>
          <p className="font-bold my-4">TERMINACIÓN</p>
          <p className="my-4">
            <b>ASISTENSI</b> se reserva el derecho de dar por terminado el presente programa de voluntariado cuando lo estime oportuno. Igualmente podrá modificar o limitar o suspender cualquier contenido de la plataforma o cualquier <b>VOLUNTARIO</b> registrado en la misma. Asistensi se reserva el derecho de negarle el acceso a cualquier <b>VOLUNTARIO</b> sin necesidad de previa notificación, en cualquier momento y sin que esto acarree responsabilidad alguna.
          </p>
          <p className="font-bold my-4"><u>OTRAS ACTIVIDADES PROHIBIDAS</u></p>
          <p className="my-4">
            <u>
              El <b>VOLUNTARIO</b> se compromete a no realizar ninguna de las siguientes <b>ACTIVIDADES PROHIBIDAS</b> y acepta que el incumplimiento del deber contemplado en la presente cláusula tiene consecuencias monetarias, penales y civiles, de las cuales será enteramente responsable el <b>VOLUNTARIO</b>:
            </u>
          </p>
          <ul>
            <li className="ml-8 list-disc underline">
              El cobro a <b>BENEFICIARIOS</b>, dinerario o en especie, en contraprestación por los servicios ofrecidos; 
            </li>
            <li className="ml-8 list-disc underline">
              La promoción u ofrecimiento de productos y servicios distintos a los estipulados en los presentes Términos y condiciones;
            </li>
            <li className="ml-8 list-disc underline">
              La representación ante cualquier persona, sociedad o entidad de una relación con <b>ASISTENSI</b> distinta a la descrita en los presentes Términos y condiciones;
            </li>
            <li className="ml-8 list-disc underline">
              La ejecución de actos dolosos, fraudulentos, ilegales o deshonestos que afecten de una manera adversa a los <b>BENEFICIARIOS</b> o a <b>ASISTENSI</b>; y
            </li>
            <li className="ml-8 list-disc underline">
              Campañas o comunicaciones que desacrediten la actividad de <b>ASISTENSI</b>.
            </li>
          </ul>
          <p className="font-bold my-4">CAMBIOS EN LOS TÉRMINOS Y CONDICIONES</p>
          <p className="my-4">
            <b>ASISTENSI</b> podrá realizar modificaciones a los presentes Términos y Condiciones y notificará al <b>VOLUNTARIO</b> de las mismas en un periodo de 10 (diez) días hábiles después de haber hecho dichas modificaciones, a efecto de que el <b>VOLUNTARIO</b> exprese su aceptación expresa o tácitamente, o en su caso cese la utilización de la <b>PLATAFORMA</b>. El uso continuado por parte del <b>VOLUNTARIO</b> de los servicios, después de recibir la notificación sobre los cambios en los Términos y Condiciones, supone la aceptación de las modificaciones realizadas en los mismos.
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <button className="secondary-button-nav" onClick={() => props.handleClose()}> 
          Cerrar
        </button>
      </DialogActions>
    </Dialog>
  )
}

VoluntaryTerms.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog({breakpoint: 'xs'})(VoluntaryTerms);