import React from 'react';
import {useRouter} from 'next/router';
import LayoutContainer from 'components/layout/LayoutContainer';

function SOSTerms({ocult}) {
  return (
      <div className="w-full">
        <div>
          <p className="text-center my-4">
          <b> ASISTENSI SOS (SERVICIO DE ORIENTACIÓN MÉDICA)</b>
          </p>
        </div>
        <div className="px-4">
          <p className="my-4">
            Los presentes Términos de Uso contienen las condiciones de uso que regulan el uso de la aplicación móvil y de la  página web <a href="https://www.asistensi.com" target="_blank" className="font-bold underline cursor-pointer">www.asistensi.com</a>  para ASISTENSI SOS (Orientación Médica Virtual), y de las herramientas provistas a través del mismo para la realización de orientación a distancia e intercambio de información médica y personal y cuyo titular es ASISTENSI GLOBAL INSURANCE INC. (en adelante “Asistensi”) con número de registro mercantil 44330, con domicilio social en Level 3, Cedar Court, Wildey Business Park, Wildey, St. Michael, Barbados BB14006. La utilización de los mismos está condicionado a la aceptación por parte del usuario miembro de los Términos de Uso descritos a continuación.
          </p>
          <p className="my-4">
            Lea atentamente el presente documento antes de acceder y utilizar la aplicación móvil y la página web <a href="https://www.asistensi.com" target="_blank" className="font-bold underline cursor-pointer">www.asistensi.com</a>. Al acceder y utilizarla, Usted expresa su reconocimiento y aceptación de los siguientes términos y condiciones de uso. 
          </p>
          <p className="font-bold my-4">
            Al presionar “ACEPTAR TÉRMINOS” Usted acuerda estar sujeto a lo estipulado en estos Términos de Uso. De no aceptar los Términos de Uso, Usted no está autorizado a utilizar ASISTENSI SOS.
          </p>
          <ul className="my-4">
            <b className="ml-4">1. Definiciones y Conceptos</b>
            <li className="ml-4 my-4">
            •	Orientación Médica Virtual: Sistema de Orientación Médica remota, con médicos a distancia, relacionada a cuadros patológicos, o inquietudes médicas, en donde un personal médico calificado podrá dar recomendaciones básicas acerca del manejo y tratamiento de situaciones médicas y consejos de salud en general en base a la información suministrada por el usuario.
            </li>
            <li className="ml-4 my-4">
            •	Orientación Médica Telefónica: Orientación Médica telefónica relacionada a cuadros patológicos, o inquietudes médicas, en donde un personal médico calificado podrá dar recomendaciones básicas acerca del manejo y tratamiento de situaciones médicas y consejos de salud en general en base a la información suministrada por el usuario.
            </li>
            <li className="ml-4 my-4">
              •	Orientación Médica por Video: Orientación Médica por video en tiempo real, a través de una aplicación móvil en teléfonos inteligentes (APP) o vía sitio web, relacionada a cuadros patológicos, o inquietudes médicas, en donde un personal médico calificado podrá dar recomendaciones básicas acerca del manejo y tratamiento de situaciones médicas y consejos de salud en general en base a la información suministrada por el usuario.
            </li>
            <li className="ml-4 my-4">
            •	Orientación Médica por Chat (mensajería instantánea): Orientación Médica por mensajes instantáneos tipo chat, a través de una aplicación móvil en teléfonos inteligentes (APP) o vía página web, relacionada a cuadros patológicos, o inquietudes médicas, en donde un personal médico calificado podrá dar recomendaciones básicas acerca del manejo y tratamiento de situaciones médicas y consejos de salud en general en base a la información suministrada por el usuario.
            </li>
          </ul>
          <div className="my-4">
          <p className="font-bold ml-4">2. Uso de ASISTENSI SOS (Orientación Médica Virtual)</p>
            <p className="my-4">
              Bienvenido a ASISTENSI SOS, una plataforma operada por Asistensi para brindarle acceso electrónico a Orientación Médica de salud con médicos licenciados y otros profesionales de la salud, mediante una plataforma telemática a distancia.
            </p>
            <p className="my-4">
              NO UTILICE ESTE SITIO WEB EN CASO DE EMERGENCIAS MÉDICAS. Si tiene una emergencia médica diríjase a un centro de atención médica de urgencia de inmediato, o si tiene un Plan Asistensi contratado para Usted, contacte a Asistensi a través del call center o la App.
            </p>
            <p className="my-4">
              Sus interacciones con los profesionales de salud de Asistensi, a través de consultas a distancia, no pretenden reemplazar su relación personal con su(s) proveedor(es) regulares de salud y las Orientaciones Médicas ofrecidas por Asistensi no pretenden ni están en capacidad de sustituir sus revisiones médicas regulares con su médico de confianza.
            </p>
          </div>
          <div className="my-4">
            <p className="font-bold ml-4">3. Independencia de criterio profesional</p>
            <p className="my-4">
              Asistensi provee, a través del personal de salud (médicos y paramédicos) contratados por la empresa, servicios médicos y de salud. A pesar de esto, todos nuestros profesionales de salud ofrecen sus servicios en el libre ejercicio de sus profesiones, bajo la guía de protocolos estándares y mundialmente aceptados acerca del manejo de situaciones de salud. Cualquier información, recomendación, indicación, diagnóstico, prescripción o tratamiento recibido de un profesional de salud de Asistensi proviene exclusivamente de ese profesional y no de Asistensi, la cual en ningún caso será responsable por daños o perjuicios relacionados con o derivados de diagnósticos, tratamientos u orientación médica obtenida por ellos, ni por ningún daño ni perjuicio relacionado con cualquier otra forma de información obtenida a través de la página web o aplicación móvil.
            </p>
          </div>
          <div className="my-4">
            <p className="font-bold ml-4">4. Contenido de la página web y aplicación móvil</p>
            <p className="my-4">
              Ningún contenido disponible en la página web deberá ser interpretado como prescripción médica, exceptuando las informaciones provistas directamente y de forma individual por los profesionales de salud en el libre ejercicio de sus profesiones.
            </p>
          </div>
          <div className="my-4">
            <p className="font-bold ml-4">5. Limitación de Responsabilidad</p>
            <p className="my-4">
              Al solicitar consulta para Orientación Médica, Asistensi le ofrecerá la opción de consultar con uno de los profesionales de guardia que se encuentre disponible para atenderlo. Aunque Asistensi realiza sus mejores esfuerzos para la selección y capacitación de sus recursos humanos profesionales para que cumplan con los requisitos para proveer los servicios que ofrecen, Usted es el único responsable de decidir si desea continuar con la realización de la consulta con el profesional asignado. Al proceder a realizar la consulta, Usted está aceptando la Orientación Médica con dicho profesional y por lo tanto iniciando una relación paciente-proveedor directa con él mismo. Usted asume por lo tanto la responsabilidad de aceptar la Orientación Médica con el profesional asignado a través de Asistensi.
            </p>
          </div>
          <div className="my-4">
            <p className="font-bold ml-4">6. Registro como Miembro</p>
            <p className="my-4">
              Para tener acceso a los servicios de ASISTENSI SOS Usted deberá solicitar la afiliación a la Membresía de Asistensi a través de la página web o aplicación móvil (App) suministrando cierta información básica. Con la excepción de sus cuentas de dependientes, Usted acuerda no crear más de una cuenta de usuario, ni crear una cuenta para ninguna otra persona. Asimismo, Usted se compromete a proveer información correcta, verdadera y actualizada de su persona al momento de registrarse o al momento de hacer cualquier actualización de su ficha de información personal. En caso de proveer información falsa o incorrecta nos reservamos el derecho de suspender su cuenta y negarle el acceso al uso del sistema.
            </p>
          </div>
          <div className="my-4">
            <p className="font-bold ml-4">7. Prohibición de uso ilegal o indebido</p>
            <p className="my-4">
              Usted se compromete a no utilizar la página web y/o la aplicación móvil para ninguna finalidad que sea ilegal o esté prohibida de acuerdo con los Términos de Uso.
            </p>
          </div>
        </div>
      </div>
  )
}

export default SOSTerms;