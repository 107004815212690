import React from 'react';
import {useRouter} from 'next/router';
import LayoutContainer from 'components/layout/LayoutContainer';

function SOSPrivacyPolicy({ocult}) {
  const router = useRouter();
  return (
    <LayoutContainer ocult={router?.asPath === '/m/politica-privacidad-sos' ? true : ocult}>
      <div className={router?.asPath === '/m/politica-privacidad-sos' ? 'container my-16' : 'w-full'}>
        <p className="text-center font-bold mb-4">POLÍTICA DE PRIVACIDAD Y USO DE DATOS PERSONALES ASISTENSI SOS</p>
        <div className="w-full m-auto">
          <p className="my-4 text-base leading-relaxed">
            La Política de privacidad le proporciona información sobre la recopilación y el uso de la Información Personal que nos proporciona cuando navega por nuestra página web y/o App y utiliza el beneficio de Orientación Médica Virtual (en adelante “ASISTENSI SOS”).
          </p>
          <p className="my-4 text-base leading-relaxed">
            Para acceder al beneficio ASISTENSI SOS, el usuario deberá suministrar algunos datos, los cuales serán almacenados temporalmente para recibir una respuesta electrónica o en caso de urgente necesidad debido al estado de salud, la información se enviará a un médico para su revisión y criterio médico a través de la página web de nuestra propiedad <a href="/" target="_blank" className="text-purple-500 font-bold underline">www.asistensi.com.ve</a>
          </p>
          <p className="my-4 text-base leading-relaxed">
            Para el caso de los menores de edad sólo podrán utilizar el beneficio a través de las personas que tengan la patria potestad sobre ellos.
          </p>
          <p className="my-4 text-base leading-relaxed">
            La presente política de privacidad fue creada para ayudarle a entender cómo se recopila y almacena su información.
          </p>
          <ul className="text-base leading-relaxed pl-4">
            <li>
              <b>1.	¿Quién es el Responsable del tratamiento?</b>
              <div className="my-2">Identidad del Responsable: Asistensi Global Insurance Inc. </div>
              <div className="my-2">Domicilio: Level 3, Cedar Court, Wildey Business Park, Wildey, St. Michael, Barbados BB14006, inscrito en el Registro de Barbados bajo el número 44330 y número TIN 1000222885424</div>
              <div className="my-2">Correo electrónico: <a href="mailto:dpo@asistensi.com" target="_blank" className="underline text-purple-500 font-bold">dpo@asistensi.com</a></div>
              <div className="my-2">ASISTENSI, es propietaria de este sitio web, cumple escrupulosamente con toda la normativa de protección de datos que es aplicable y se han implementado todas las medidas técnicas y organizativas necesarias para garantizar el nivel de seguridad adecuado. </div>
              <div className="my-2">Si tiene alguna duda acerca de esta política se podrá poner en contacto con el Delegado de Protección de Datos: <a href="mailto:dpo@asistensi.com" target="_blank" className="underline text-purple-500 font-bold">dpo@asistensi.com</a> quien tratará de resolver cualquier duda que Usted tenga. </div>
            </li>
          </ul>
          <ul className="text-base leading-relaxed pl-4 mt-4">
            <li>
              <b>2.	¿Con qué finalidad trata la información personal ASISTENSI?</b>
              <div className="my-2">-  ASISTENSI utiliza la información personal suministrada por los usuarios para que puedan acceder y registrarse en la página web y/o App y obtener los beneficios de ASISTENSI SOS.</div>
              <div className="my-2">-  ASISTENSI utilizará la información personal para dar los servicios adquiridos y tramitar los beneficios de ASISTENSI SOS.</div>
              <div className="my-2">-  ASISTENSI utilizará la información personal con el fin de gestionar consultas, dudas o requerimientos de información adicional.</div>
              <div className="my-2">-	ASISTENSI utilizará la información personal con el fin de analizar el uso de la página web y/o App y mejorar el rendimiento, para más información visita la <a href="/politica-cookies" target="_blank" className="underline text-purple-500 font-bold">política de cookies</a>.</div>
            </li>
          </ul>
          <ul className="text-base leading-relaxed pl-4 mt-4">
            <li>
              <b>3.	¿Qué Información Personal recopila ASISTENSI?</b>
              <div className="my-2">ASISTENSI recopila los datos personales cuando el usuario se registra para ASISTENSI SOS a través de un formulario de la página web ASISTENSI <a href="/perfil/sos" target="_blank" className="text-purple-500 font-bold underline">https://asistensi.com.ve/perfil/sos.</a> Para ello, el usuario deberá suministrar datos de salud rellenando el respectivo cuestionario con el fin de proporcionarle una Orientación Médica Virtual o dependiendo del estado de salud, una respuesta por parte del médico en cuestión acorde a su criterio y datos otorgados para ello.</div>
              <div className="my-2">ASISTENSI podrá tratar también los datos identificativos incluidos en el registro de membresía principal para los fines descritos en el numeral anterior. Para más información sobre el tratamiento de sus datos personales consulte nuestra política de privacidad principal.</div>
              <div className="my-2">
                Adicionalmente, Cuando el usuario visita nuestra página web, recopilamos información de registro 
                estándar y, a través de cookies, detalles de su comportamiento en línea, siempre que nos haya dado su consentimiento, ya sea al continuar navegando o al aceptar explícitamente el uso de cookies. Usaremos estos datos para analizar y mejorar la experiencia de navegación de los usuarios. Puede acceder a nuestra Política de Cookies aquí <a href="/politica-cookies" target="_blank" className="font-bold text-purple-500 underline">https://www.asistensi.com.ve/politica-cookies</a>, en este sentido, se recomienda revisar estos términos periódicamente.
              </div>
            </li>
          </ul>
          <ul className="text-base leading-relaxed pl-4 mt-4">
            <li>
              <b>4.	¿Cuál es la base legal para el tratamiento de la información personal?</b>
              <div className="my-2">El tratamiento de la información personal está basado en la relación contractual o precontractual establecida con ASISTENSI y necesaria para proporcionar la prestación de Orientación Médica Virtual y su consentimiento expreso para tratar los datos de salud proporcionados con los fines indicados.</div>
            </li>
          </ul>
          <ul className="text-base leading-relaxed pl-4 mt-4">
            <li>
              <b>5.	¿Cuánto tiempo tratará la información ASISTENSI?</b>
              <div className="my-2">
                Los datos personales serán tratados mientras la relación contractual y/o precontractual se encuentre en vigor y/o siga siendo necesario para la finalidad indicada. Cabe recalcar que los datos de salud se tratarán sólo temporalmente para los casos especificados en que el estado de salud sea delicado según el criterio médico y hasta que sea resuelto el caso presentado mediante el cuestionario de ASISTENSI SOS. 
              </div>
            </li>
          </ul>
          <ul className="text-base leading-relaxed pl-4 mt-4">
            <li>
              <b>6.	¿ASISTENSI realiza Transferencias internacionales?</b>
              <div className="my-2">
                La información personal será transferida y almacenada en Amazon Web Services, con domicilio en Estados Unidos de América, en un fichero administrado por ASISTENSI. Igualmente, en el caso de transferir la información personal a terceros en otras partes del mundo con los fines descritos en esta política de privacidad, se garantizarán todas las medidas adecuadas para proteger la confidencialidad de la misma. Estas medidas pueden incluir la implementación de cláusulas contractuales estándar en la medida reconocida por la ley, contratos, la obtención de su consentimiento u otros medios legales para la transferencia de información personal.
              </div>
            </li>
          </ul>
          <ul className="text-base leading-relaxed pl-4 mt-4">
            <li>
              <b>7.	¿Quién tiene acceso a su Información Personal?</b>
              <div className="my-2">
                ASISTENSI puede compartir su información personal en los siguientes casos:
              </div>
              <li className="my-2">
                a)	Para poder proveer el servicio de Orientación Médica Virtual a través de un médico de nuestra red de proveedores;
              </li>
              <li className="my-2">
                b)	Adicionalmente ASISTENSI podrá compartir su información con las demás empresas matrices actuales o futuras, subsidiarias, empresas conjuntas u otras empresas bajo un control común ("Afiliadas"), en cuyo caso se le solicitará a las Afiliadas que respeten la presente Política de Privacidad. 
              </li>
              <li className="my-2">
                c)	Se podrá ceder la información personal por razones de transformaciones estructurales societarias, como escisión, fusión o cesión global de activos y pasivos. 
              </li>
              <div className="my-2">En algunos casos, ASISTENSI necesitará proveedores para que traten datos a su nombre, pero solo utilizarán dichos datos según las instrucciones proporcionadas. Por ejemplo, en caso de necesitar un proveedor para almacenar los datos recabados en este sitio web o la App.</div>
            </li>
          </ul>
          <ul className="text-base leading-relaxed pl-4 mt-4">
            <li>
              <b>8.	Derechos del Usuario</b>
              <div className="my-2">
                De acuerdo con la normativa aplicable en su país o zona geográfica, Usted tiene derecho a:
              </div>
              <li className="my-2">
                •	Saber si sus datos personales están siendo tratados y, en su caso, acceder a ellos;
              </li>
              <li className="my-2">
                •	Rectificar sus datos personales si fueran inexactos o incompletos;
              </li>
              <li className="my-2">
                •	Solicitar la eliminación de sus datos si no son necesarios para los fines indicados;
              </li>
              <li className="my-2">
                •	Exigir que se delimite el procesamiento de sus datos personales;
              </li>
              <li className="my-2">
                •	Transferir sus datos personales a otro proveedor de su elección (portabilidad); y
              </li>
              <li className="my-2">
                •	Oponerse al tratamiento de sus datos personales en cualquier momento.
              </li>
              <div className="my-2">
                Para ello, deberá enviar una solicitud acompañada, en aquellos casos que consideremos necesario, de un documento de identidad a <a href="mailto:dpo@asistensi.com" target="_blank" className="text-purple-500 font-bold underline">dpo@asistensi.com.</a>
              </div>
              <div className="my-2">
                Asimismo, puede presentar una reclamación ante la Autoridad de Protección de Datos correspondiente si cree que se han infringido sus derechos.
              </div>
            </li>
          </ul>
          <ul className="text-base leading-relaxed pl-4 mt-4">
            <li>
              <b>9.	Ley Aplicable</b>
              <div className="my-2">
                Los presentes Términos y Condiciones, Política de Privacidad y Política de Cookies, así como cualquier relación entre usted, como Usuario, la página web de ASISTENSI incluida su App, se regirán por la legislación de Barbados.
              </div>
              <div className="my-2">
                Ambas partes acuerdan que todas las reclamaciones y controversias que surjan de los presentes Términos y Condiciones, Política de Privacidad y Política de Cookies o de la relación entre el Usuario y la página web de ASISTENSI incluida su App, serán resueltas por los Juzgados y Tribunales de Bridgetown, Barbados, con renuncia expresa a cualquier otro fuero aplicable. Esto será aplicable siempre que no sea aplicable la normativa local de su país de residencia.
              </div>
            </li>
          </ul>
          <ul className="text-base leading-relaxed pl-4 mt-4">
            <li>
              <b>10.	Seguridad</b>
              <div className="my-2">
                ASISTENSI reconoce la importancia de la seguridad, integridad y confidencialidad de su información. Se han implementado medidas de seguridad técnicas, administrativas y físicas que están diseñadas para proteger la información de los usuarios contra el acceso, el uso y la modificación no autorizados. Periódicamente, se revisan los procedimientos de seguridad para considerar nuevas tecnologías y métodos adecuados. Tenga en cuenta que, a pesar de los mejores esfuerzos, ninguna medida de seguridad es perfecta ni impenetrable. Retendremos su información personal durante el período de tiempo necesario para cumplir los propósitos que se describen en esta política de privacidad, a menos que se requiera un período de retención mayor o que lo permita la ley.
              </div>
            </li>
          </ul>
          <ul className="text-base leading-relaxed pl-4 mt-4">
            <li>
              <b>11.	Enmiendas</b>
              <div className="my-2">
                ASISTENSI se reserva el derecho de introducir cambios en la página web, en los presentes Términos y Condiciones, Política de Privacidad y Política de Cookies en cualquier momento sin previo aviso notificando a los usuarios de dichos cambios. En este sentido, se recomienda revisar estos términos periódicamente.
              </div>
            </li>
          </ul>
          <div className="my-2">
            Para más información sobre la página web podrá acceder a <a href="/terminos-uso" target="_blank" className="text-purple-500 underline font-bold">https://asistensi.com.ve/terminos-uso</a>
          </div>
          <div className="my-2 flex justify-end">
            Última actualización: <b className="ml-1">1 de Octubre 2021</b>
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}

export default SOSPrivacyPolicy;