import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from '@material-ui/core';
import TermsDIP from './TermsDIP';


class TermsConditionDialog extends Component {
	render() {
		const { fullScreen } = this.props;
		return (
			<Dialog
				open={this.props.open}
				maxWidth="md"
				onClose={this.props.handleClose}
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
				fullScreen={fullScreen}
			>
				<DialogTitle className="text-gray-900 font-bold"></DialogTitle>
				<DialogContent>
					<TermsDIP />
				</DialogContent>
				<DialogActions>
					<div className="flex justify-around w-full my-4">
						<button
							className="secondary-button-nav mr-2"
							onClick={() => this.props.handleClose(false, "DIP")}
						>
							No acepto
						</button>
						<button
							className="white-button-nav"
							onClick={() => this.props.handleClose(true, "DIP")}
						>
							Acepto
						</button>
					</div>
				</DialogActions>
			</Dialog>
		);
	}
}

TermsConditionDialog.propTypes = {
	fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(TermsConditionDialog);
