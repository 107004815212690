import React from 'react'

const CovidTerm = () => {
    return (
        <div className="bg-white border border-purple-300 text-purple-500 p-5 rounded-xl">
            Tomando en cuenta lo dispuesto por la Superintendencia de la Actividad Aseguradora  
            <a href="http://www.sudeaseg.gob.ve/documents/2021/03/circular-saa-9-1109.pdf/" className="font-bold cursor-pointer" target="_blank"> (SUDEASEG)</a> en su 
            circular Nª SAA-9-1109 del 26 marzo 2021, con efectividad desde el viernes 26 marzo 2021, el plazo de 
            espera para la cobertura por COVID-19 es de 30 días continuos y los límites diarios de cobertura se establecen 
            por 10 días (extensible a 14 días en casos graves) por el monto de 15 <a href="https://www.petro.gob.ve/" className="font-bold cursor-pointer" target="_blank"> petros </a>
            para hospitalización por día y 30 <a href="https://www.petro.gob.ve/" className="font-bold cursor-pointer" target="_blank"> petros </a> por 
            unidad de cuidados intensivos por día.
        </div>
    )
}

export default CovidTerm;
