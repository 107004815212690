import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import SOSPrivacyPolicy from '../../../pages/privacypolicy/sos';

const logo = '/static/images/Asistensi_logotipo.svg';

class MedicalPrivacyPolicy extends Component {

  render() {
    const { fullScreen } = this.props;
    
    return (
      <Dialog
        open={this.props.open}
        maxWidth="md"
        onClose={this.props.handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        fullScreen={fullScreen}
      >
        <DialogTitle>
          <img loading="lazy" src={logo} alt='Asistensi_logotipo' className="w-auto h-12" />
        </DialogTitle>

        <DialogContent>
          <SOSPrivacyPolicy ocult={true}/>
        </DialogContent>
        <DialogActions>
          <button className="secondary-button-nav" onClick={this.props.handleClose}>
            Cerrar
          </button>
        </DialogActions>
      </Dialog>
    )
  }
}

MedicalPrivacyPolicy.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog({breakpoint: 'xs'})(MedicalPrivacyPolicy);